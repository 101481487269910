import React from 'react';
import { AudioProvider } from './contexts/AudioContext';
import { useAuth } from './contexts/AuthContext';
import SongList from './components/SongList';
import GlobalControls from './components/GlobalControls';
import { Routes, Route, Link } from 'react-router-dom';
import EditSong from './components/EditSong';
import Login from './components/Login';
import Upload from './components/Upload';
import { PopupProvider } from './contexts/PopupContext';
import PopupStack from './components/PopupStack';
import PrivateRoute from './components/PrivateRoute';

function App() {
  const { currentUser } = useAuth();

  return (
    <PopupProvider>
      <AudioProvider>
        <div className="min-h-screen bg-gray-100 dark:bg-gray-900 transition-colors duration-200">
          <header className="bg-white dark:bg-gray-800 shadow">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
              <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Steven Song Share</h1>
              {currentUser && (
                <Link
                  to="/upload"
                  className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
                >
                  Upload
                </Link>
              )}
            </div>
          </header>
          <main>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <Routes>
                <Route path="/edit/:id" element={<PrivateRoute><EditSong /></PrivateRoute>} />
                <Route path="/login" element={<Login />} />
                <Route path="/upload" element={<PrivateRoute><Upload /></PrivateRoute>} />
                <Route path="/" element={<SongList songs={[]} setCurrentSong={null} />} />
              </Routes>
            </div>
          </main>
          <footer className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 shadow">
            <GlobalControls />
          </footer>
          <PopupStack />
        </div>
      </AudioProvider>
    </PopupProvider>
  );
}

export default App;