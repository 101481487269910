import React, { createContext, useContext, useState, useCallback } from 'react';

const PopupContext = createContext();

export function PopupProvider({ children }) {
  const [popups, setPopups] = useState([]);
  const [centerPopup, setCenterPopup] = useState(null);

  const addPopup = useCallback((popupData) => {
    const id = Date.now();
    const newPopup = typeof popupData === 'string'
      ? { id, message: popupData, duration: 3000 }
      : { id, ...popupData };

    if (newPopup.center) {
      setCenterPopup(newPopup);
    } else if (process.env.NODE_ENV === 'development') {
      setPopups(prevPopups => [...prevPopups, newPopup]);

      if (newPopup.duration > 0) {
        setTimeout(() => {
          setPopups(prevPopups => prevPopups.filter(popup => popup.id !== id));
        }, newPopup.duration);
      }
    }
  }, []);

  const removePopup = useCallback((id) => {
    setPopups(prevPopups => prevPopups.filter(popup => popup.id !== id));
    setCenterPopup(null);
  }, []);

  return (
    <PopupContext.Provider value={{ popups, centerPopup, addPopup, removePopup }}>
      {children}
    </PopupContext.Provider>
  );
}

export const usePopup = () => useContext(PopupContext);

export function PopupStack() {
  const { popups, centerPopup, removePopup } = usePopup();

  return (
    <>
      {/* Center popup (always visible) */}
      {centerPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg p-6 max-w-sm">
            <p className="text-gray-800 dark:text-gray-200 text-lg mb-4">{centerPopup.message}</p>
            {centerPopup.actions && (
              <div className="flex justify-end space-x-4">
                {centerPopup.actions.map((action, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      action.onClick();
                      removePopup(centerPopup.id);
                    }}
                    className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition-colors"
                  >
                    {action.text}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Bottom right notifications (only in development) */}
      {process.env.NODE_ENV === 'development' && (
        <div className="fixed bottom-4 right-4 z-40">
          {popups.map(popup => (
            <div key={popup.id} className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg p-4 mb-2 max-w-sm">
              <p className="text-gray-800 dark:text-gray-200">{popup.message}</p>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
