import React, { useEffect, useRef, useState, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { useAudio } from '../contexts/AudioContext';

export const generateWaveformData = async (audioBuffer) => {
  return new Promise((resolve) => {
    const peaks = [];
    const channelData = audioBuffer.getChannelData(0); // Get the first channel
    const bucketSize = Math.floor(channelData.length / 200); // 200 data points for the waveform

    for (let i = 0; i < 200; i++) {
      let start = i * bucketSize;
      let sum = 0;
      for (let j = 0; j < bucketSize; j++) {
        sum += Math.abs(channelData[start + j]);
      }
      peaks.push(sum / bucketSize);
    }

    // Normalize the peaks
    const maxPeak = Math.max(...peaks);
    const normalizedPeaks = peaks.map(peak => peak / maxPeak);

    resolve(normalizedPeaks);
  });
};

function Waveform({ audioUrl, waveformData, isPlaying, onPlayPause }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [duration, setDuration] = useState(0);
  const { currentTime, setCurrentTimeAndSeek } = useAudio();
  const [isDragging, setIsDragging] = useState(false);
  const [dragTime, setDragTime] = useState(null);

  useEffect(() => {
    if (waveformRef.current && audioUrl && waveformData) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: 'violet',
        progressColor: 'purple',
        cursorColor: 'navy',
        barWidth: 2,
        barRadius: 3,
        cursorWidth: 1,
        height: 80, // Make sure this matches the height in the CSS
        barGap: 3,
        peaks: waveformData,
        url: audioUrl,
        backend: 'MediaElement',
        interact: false, // Disable default WaveSurfer interactions
        normalize: true, // Enable normalization in WaveSurfer
      });

      wavesurfer.current.on('ready', () => {
        setDuration(wavesurfer.current.getDuration());
      });

      return () => wavesurfer.current.destroy();
    }
  }, [audioUrl, waveformData]);

  useEffect(() => {
    if (wavesurfer.current && duration > 0 && !isDragging) {
      const progress = currentTime / duration;
      wavesurfer.current.seekTo(progress);
    }
  }, [currentTime, duration, isDragging]);

  const handleMouseDown = useCallback((e) => {
    setIsDragging(true);
    handleSeek(e);
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (isDragging) {
      handleSeek(e);
    }
  }, [isDragging]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setDragTime(null);
    if (dragTime !== null) {
      setCurrentTimeAndSeek(dragTime);
      if (!isPlaying) {
        onPlayPause(dragTime);
      }
    }
  }, [dragTime, isPlaying, onPlayPause, setCurrentTimeAndSeek]);

  const handleSeek = useCallback((e) => {
    if (wavesurfer.current && duration > 0) {
      const waveformRect = waveformRef.current.getBoundingClientRect();
      const clickPosition = (e.clientX - waveformRect.left) / waveformRect.width;
      const seekTime = clickPosition * duration;
      setDragTime(seekTime);
      wavesurfer.current.seekTo(clickPosition);
    }
  }, [duration]);

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleMouseUp, handleMouseMove]);

  return (
    <div className="waveform-container relative z-0">
      <div 
        className="waveform-box"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        style={{ cursor: 'pointer', height: '100px' }}
      >
        <div ref={waveformRef} className="waveform" />
      </div>
    </div>
  );
}

export default Waveform;