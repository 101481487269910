import React from 'react';
import { usePopup } from '../contexts/PopupContext';

function PopupStack() {
  const { popups, centerPopup, removePopup } = usePopup();

  return (
    <>
      {/* Center popup (always visible) */}
      {centerPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg p-6 max-w-sm">
            <p className="text-gray-800 dark:text-gray-200 text-lg mb-4">{centerPopup.message}</p>
            {centerPopup.actions && (
              <div className="flex justify-end space-x-4">
                {centerPopup.actions.map((action, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      action.onClick();
                      removePopup(centerPopup.id);
                    }}
                    className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition-colors"
                  >
                    {action.text}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Bottom right notifications (only in development) */}
      {process.env.NODE_ENV === 'development' && (
        <div className="fixed bottom-20 right-4 z-50 flex flex-col-reverse items-end">
          {popups.map((popup, index) => (
            <div
              key={popup.id}
              className="bg-indigo-600 text-white px-4 py-2 rounded shadow-lg mb-2 transition-all duration-300"
              style={{
                opacity: 1 - index * 0.2,
                transform: `translateY(-${index * 10}px)`,
              }}
            >
              {popup.message}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default PopupStack;
