import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const AudioContext = createContext();

export function AudioProvider({ children }) {
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [showGlobalControls, setShowGlobalControls] = useState(true);
  const location = useLocation();
  const audioRef = useRef(new Audio());
  const fadeInterval = useRef(null);

  useEffect(() => {
    console.log('isPlaying changed:', isPlaying);
    const audio = audioRef.current;

    const handleCanPlay = () => {
      console.log('Audio can play, isPlaying:', isPlaying);
      if (isPlaying) {
        audio.play().catch(error => console.error('Error playing audio:', error));
      }
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
    };

    audio.addEventListener('canplay', handleCanPlay);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('canplay', handleCanPlay);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [isPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    const updateTime = () => setCurrentTime(audio.currentTime);
    const updateDuration = () => setDuration(audio.duration);

    audio.addEventListener('timeupdate', updateTime);
    audio.addEventListener('loadedmetadata', updateDuration);

    return () => {
      audio.removeEventListener('timeupdate', updateTime);
      audio.removeEventListener('loadedmetadata', updateDuration);
    };
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    const path = location.pathname;

    if (path === '/upload' || path.startsWith('/edit/')) {
      setShowGlobalControls(false);
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      }
    } else {
      setShowGlobalControls(true);
    }
  }, [location, isPlaying]);

  const fadeAudio = useCallback((start, end, duration, callback) => {
    const audio = audioRef.current;
    let currentTime = 0;
    const increment = 20;
    
    if (fadeInterval.current) clearInterval(fadeInterval.current);
    
    fadeInterval.current = setInterval(() => {
      currentTime += increment;
      const volume = start + (end - start) * (currentTime / duration);
      audio.volume = Math.max(0, Math.min(1, volume));
      
      if (currentTime >= duration) {
        clearInterval(fadeInterval.current);
        if (callback) callback();
      }
    }, increment);
  }, []);

  const togglePlayPause = useCallback(() => {
    const audio = audioRef.current;
    if (isPlaying) {
      fadeAudio(audio.volume, 0, 300, () => {
        audio.pause();
        setIsPlaying(false);
      });
    } else {
      audio.play().then(() => {
        fadeAudio(0, volume, 300);
        setIsPlaying(true);
      }).catch(error => {
        console.error('Error playing audio:', error);
        setIsPlaying(false);
      });
    }
  }, [isPlaying, volume, fadeAudio]);

  const playSong = useCallback((song) => {
    console.log('playSong called with:', song);
    const audio = audioRef.current;
    
    if (currentSong && currentSong.id === song.id) {
      console.log('Toggling play/pause for current song');
      togglePlayPause();
    } else {
      console.log('Setting up new song:', song.url);
      audio.src = song.url;
      audio.load();
      audio.volume = volume;
      console.log('Audio volume set to:', volume);
      setCurrentSong(song);
      setIsPlaying(true);
      audio.play().then(() => {
        console.log('Audio started playing, current volume:', audio.volume);
      }).catch(error => {
        console.error('Error playing audio:', error);
        setIsPlaying(false);
      });
    }
  }, [currentSong, volume, togglePlayPause]);

  const handleSeek = useCallback((time) => {
    const audio = audioRef.current;
    audio.currentTime = time;
    setCurrentTime(time);
  }, []);

  const setVolumeSmooth = useCallback((newVolume) => {
    const audio = audioRef.current;
    fadeAudio(audio.volume, newVolume, 200, () => {
      setVolume(newVolume);
    });
  }, [fadeAudio]);

  const setCurrentTimeAndSeek = useCallback((time) => {
    const audio = audioRef.current;
    audio.currentTime = time;
    setCurrentTime(time);
  }, []);

  return (
    <AudioContext.Provider value={{
      currentSong,
      setCurrentSong,
      isPlaying,
      togglePlayPause,
      currentTime,
      duration,
      volume,
      setVolumeSmooth,
      handleSeek,
      playSong,
      setCurrentTimeAndSeek,
      showGlobalControls
    }}>
      {children}
    </AudioContext.Provider>
  );
}

export const useAudio = () => useContext(AudioContext);

async function fetchSongById(id) {
  try {
    const songDoc = await getDoc(doc(db, 'songs', id));
    if (songDoc.exists()) {
      return { id: songDoc.id, ...songDoc.data() };
    }
  } catch (error) {
    console.error('Error fetching song:', error);
  }
  return null;
}