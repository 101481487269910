import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link, useLocation } from 'react-router-dom';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAudio } from '../contexts/AudioContext';
import { usePopup } from '../contexts/PopupContext';
import Waveform from './Waveform';

// Add these SVG components
const PlayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
    <path fillRule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clipRule="evenodd" />
  </svg>
);

const PauseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
    <path fillRule="evenodd" d="M6.75 5.25a.75.75 0 01.75-.75H9a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H7.5a.75.75 0 01-.75-.75V5.25zm7.5 0A.75.75 0 0115 4.5h1.5a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H15a.75.75 0 01-.75-.75V5.25z" clipRule="evenodd" />
  </svg>
);

const ShareIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
    <path d="M13 4.5a2.5 2.5 0 11.702 1.737L6.97 9.604a2.518 2.518 0 010 .792l6.733 3.367a2.5 2.5 0 11-.671 1.341l-6.733-3.367a2.5 2.5 0 110-3.475l6.733-3.366A2.52 2.52 0 0113 4.5z" />
  </svg>
);

function SongItem({ song, onDelete, isExpanded, onExpand, isPlaying, onPlayPause }) {
  const { currentUser } = useAuth();
  const [isDeleting, setIsDeleting] = useState(false);
  const { currentSong, isPlaying: currentIsPlaying, playSong, togglePlayPause, currentTime, setCurrentTimeAndSeek } = useAudio();
  const location = useLocation();
  const { addPopup } = usePopup();
  const textAreaRef = useRef(null);
  const [isTitleOverflowing, setIsTitleOverflowing] = useState(false);
  const titleRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (titleRef.current && containerRef.current) {
        setIsTitleOverflowing(titleRef.current.scrollWidth > containerRef.current.clientWidth);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [song.title]);

  const handlePlayPause = (e, seekTime) => {
    e.stopPropagation(); // Prevent the click from triggering onExpand
    if (seekTime !== undefined) {
      // If seekTime is provided, always play (or continue playing)
      if (!isPlaying) {
        onPlayPause(song, seekTime);
      } else {
        // If already playing, just seek
        setCurrentTimeAndSeek(seekTime);
      }
    } else {
      // Toggle play/pause when no seekTime is provided
      onPlayPause(song);
    }
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this song?')) {
      setIsDeleting(true);
      try {
        await deleteDoc(doc(db, 'songs', song.id));
        onDelete(song.id);
      } catch (error) {
        console.error('Error deleting song:', error);
        alert('Failed to delete the song. Please try again.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleShare = (e) => {
    e.stopPropagation();
    const shareUrl = `https://music.steventhings.com/#song-${song.id}`;
    if (navigator.share) {
      navigator.share({
        title: song.title,
        text: 'Check out this song!',
        url: shareUrl,
      }).then(() => {
        addPopup('Shared successfully!');
      }).catch((error) => {
        console.error('Error sharing:', error);
        fallbackCopyToClipboard(shareUrl);
      });
    } else {
      fallbackCopyToClipboard(shareUrl);
    }
  };

  const fallbackCopyToClipboard = (text) => {
    const textArea = textAreaRef.current;
    textArea.value = text;
    textArea.style.display = 'block';
    textArea.select();
    
    try {
      document.execCommand('copy');
      addPopup('Link copied to clipboard!');
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      addPopup('Failed to copy link. Please try again.');
    }
    
    textArea.style.display = 'none';
  };

  return (
    <div id={`song-${song.id}`} className="bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden mb-4 hover-lift transition-colors duration-200">
      <textarea
        ref={textAreaRef}
        aria-hidden="true"
        style={{ position: 'absolute', left: '-9999px' }}
      />
      <div className="px-4 py-3 cursor-pointer" onClick={() => onExpand(song.id)}>
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div ref={containerRef} className="flex-grow mb-2 sm:mb-0 sm:mr-4 overflow-hidden title-container">
            <h3 
              ref={titleRef}
              className={`text-base font-semibold text-gray-900 dark:text-white ${
                isTitleOverflowing ? 'animate-scroll' : ''
              }`}
            >
              {song.title}
            </h3>
          </div>
          <div className="flex items-center justify-between sm:justify-end w-full sm:w-auto">
            <div className="text-sm text-gray-600 dark:text-gray-300 flex flex-col sm:flex-row sm:space-x-4 sm:mr-8">
              <span>{song.duration}</span>
              <span>{new Date(song.date).toLocaleDateString()}</span>
            </div>
            <div className="flex items-center space-x-2">
              <button 
                className="p-2 rounded-full bg-indigo-600 text-white hover:bg-indigo-700 transition-colors duration-200 focus:outline-none"
                onClick={(e) => handlePlayPause(e)}
              >
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </button>
              <button
                className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200 focus:outline-none"
                onClick={handleShare}
              >
                <ShareIcon />
              </button>
              {currentUser && (
                <>
                  <Link
                    to={`/edit/${song.id}`}
                    className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200 focus:outline-none"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Edit
                  </Link>
                  <button
                    className="p-2 rounded-full bg-red-200 dark:bg-red-700 text-red-600 dark:text-red-300 hover:bg-red-300 dark:hover:bg-red-600 transition-colors duration-200 focus:outline-none"
                    onClick={handleDelete}
                    disabled={isDeleting}
                  >
                    {isDeleting ? '...' : 'Del'}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className="border-t border-gray-200 dark:border-gray-700 px-4 py-3">
          <p className="text-sm text-gray-700 dark:text-gray-300 mb-4">{song.description}</p>
          {song.waveformData && isPlaying && (
            <Waveform
              audioUrl={song.url}
              waveformData={song.waveformData}
              isPlaying={isPlaying}
              onPlayPause={(seekTime) => handlePlayPause(new Event('click'), seekTime)}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default SongItem;
