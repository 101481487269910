import React, { useState, useEffect, useCallback, useRef } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import SongItem from './SongItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAudio } from '../contexts/AudioContext';
import { usePopup } from '../contexts/PopupContext';

function SongList() {
  const [songs, setSongs] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc'); // 'desc' for newest to oldest, 'asc' for oldest to newest
  const [expandedSongId, setExpandedSongId] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { currentSong, isPlaying, togglePlayPause, playSong } = useAudio();
  const { addPopup } = usePopup();
  const isInitialMount = useRef(true);
  const pendingAutoplay = useRef(null);

  const updateAnchor = useCallback(() => {
    if (expandedSongId) {
      navigate(`${location.pathname}#song-${expandedSongId}`, { replace: true });
      const element = document.getElementById(`song-${expandedSongId}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate(location.pathname, { replace: true });
    }
  }, [expandedSongId, location.pathname, navigate]);

  useEffect(() => {
    updateAnchor();
  }, [updateAnchor]);

  useEffect(() => {
    fetchSongs();
  }, []);

  useEffect(() => {
    const songId = location.hash.replace('#song-', '');
    console.log('Hash changed, songId:', songId);
    
    if (songId && isInitialMount.current) {
      console.log('Setting pending autoplay for songId:', songId);
      pendingAutoplay.current = songId;
      setExpandedSongId(songId);
    }
    
    isInitialMount.current = false;
  }, [location]);

  useEffect(() => {
    if (songs.length > 0 && pendingAutoplay.current) {
      const songId = pendingAutoplay.current;
      console.log('Songs loaded, attempting autoplay for songId:', songId);
      const song = songs.find(s => s.id === songId);
      if (song) {
        console.log('Song found, ready to play:', song.title);
        showAutoplayPopup(song);
      } else {
        console.log('Song not found in loaded songs');
      }
      pendingAutoplay.current = null;
    }
  }, [songs, playSong, addPopup]);

  const showAutoplayPopup = (song) => {
    addPopup({
      message: `Do you want to play "${song.title}"?`,
      center: true, // This will make it appear in the center
      actions: [
        {
          text: 'Play',
          onClick: () => {
            console.log('User chose to play the song');
            playSong(song);
          }
        },
        {
          text: 'Continue to site',
          onClick: () => {
            console.log('User chose to continue to site');
            // You can add any additional logic here if needed
          }
        }
      ]
    });
  };

  useEffect(() => {
    if (currentSong && isPlaying) {
      setExpandedSongId(currentSong.id);
      addPopup(`Now playing: ${currentSong.title}`);
    }
  }, [currentSong, isPlaying, addPopup]);

  const fetchSongs = async () => {
    try {
      const songsCollection = collection(db, 'songs');
      const songSnapshot = await getDocs(songsCollection);
      const songList = songSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSongs(sortSongs(songList, sortOrder));
      addPopup(`Fetched ${songList.length} songs`);
      console.log('Fetched songs:', songList);
    } catch (err) {
      console.error('Error fetching songs:', err);
      setError('Failed to fetch songs. Please try again later.');
      addPopup('Error: Failed to fetch songs');
    }
  };

  const sortSongs = (songList, order) => {
    return songList.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return order === 'desc' ? dateB - dateA : dateA - dateB;
    });
  };

  const toggleSortOrder = () => {
    const newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
    setSortOrder(newSortOrder);
    setSongs(sortSongs([...songs], newSortOrder));
    addPopup(`Sorted songs ${newSortOrder === 'desc' ? 'newest to oldest' : 'oldest to newest'}`);
  };

  const handleExpand = useCallback((songId) => {
    setExpandedSongId(prevId => prevId === songId ? null : songId);
    // updateAnchor will be called due to the useEffect hook when expandedSongId changes
  }, []);

  const handlePlayPause = useCallback((song) => {
    if (currentSong && currentSong.id === song.id) {
      togglePlayPause();
      addPopup(`${isPlaying ? 'Paused' : 'Resumed'} ${song.title}`);
    } else {
      playSong(song);
      addPopup(`Started playing ${song.title}`);
      setExpandedSongId(song.id);
    }
    // Removed setExpandedSongId(song.id) from here
  }, [currentSong, isPlaying, togglePlayPause, playSong, addPopup]);

  const handleDelete = (deletedSongId) => {
    setSongs(songs.filter(song => song.id !== deletedSongId));
    if (expandedSongId === deletedSongId) {
      setExpandedSongId(null);
    }
    updateAnchor();
    addPopup(`Deleted song ${deletedSongId}`);
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="pb-4"> {/* Add padding-bottom here */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Songs ({songs.length})</h2>
        <button 
          onClick={toggleSortOrder}
          className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition-colors"
        >
          Sort: {sortOrder === 'desc' ? 'Newest to Oldest' : 'Oldest to Newest'}
        </button>
      </div>
      <div className="px-2 sm:px-0 pb-10"> {/* Add padding-bottom here as well */}
        {songs.length === 0 ? (
          <p>No songs available. {error ? 'Error occurred while fetching.' : 'Loading...'}</p>
        ) : (
          songs.map(song => (
            <SongItem
              key={song.id}
              song={song}
              onDelete={handleDelete}
              isExpanded={song.id === expandedSongId}
              onExpand={handleExpand}
              isPlaying={currentSong && currentSong.id === song.id && isPlaying}
              onPlayPause={handlePlayPause}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default SongList;
