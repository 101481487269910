import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyCbN6po4Zc8sRBQiK4ACATFtAyGTnElZM0",
    authDomain: "music.steventhings.com",
    projectId: "steven-song-share",
    storageBucket: "steven-song-share.appspot.com",
    messagingSenderId: "680774175616",
    appId: "1:680774175616:web:39e03b8b8ab5d732d18f65"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
