import React, { useCallback, useState, useEffect } from 'react';
import { useAudio } from '../contexts/AudioContext';
import { Slider } from './ui/slider';
import { Volume2, VolumeX, Volume1 } from 'lucide-react';

// SVG components
const PlayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
    <path fillRule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clipRule="evenodd" />
  </svg>
);

const PauseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
    <path fillRule="evenodd" d="M6.75 5.25a.75.75 0 01.75-.75H9a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H7.5a.75.75 0 01-.75-.75V5.25zm7.5 0A.75.75 0 0115 4.5h1.5a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H15a.75.75 0 01-.75-.75V5.25z" clipRule="evenodd" />
  </svg>
);

function GlobalControls() {
  const { 
    currentSong, 
    isPlaying, 
    togglePlayPause, 
    currentTime,
    duration, 
    volume, 
    setVolumeSmooth, 
    setCurrentTimeAndSeek,
    showGlobalControls
  } = useAudio();
  const [localVolume, setLocalVolume] = useState(volume);
  const [previousVolume, setPreviousVolume] = useState(volume);
  const [isPlaybackHovered, setIsPlaybackHovered] = useState(false);
  const [isVolumeHovered, setIsVolumeHovered] = useState(false);

  useEffect(() => {
    setLocalVolume(volume);
  }, [volume]);

  const VolumeIcon = useCallback(() => {
    if (localVolume === 0) return <VolumeX className="w-6 h-6" />;
    if (localVolume < 0.5) return <Volume1 className="w-6 h-6" />;
    return <Volume2 className="w-6 h-6" />;
  }, [localVolume]);

  const handleVolumeChange = useCallback((value) => {
    const newVolume = value[0];
    setLocalVolume(newVolume);
    setVolumeSmooth(newVolume);
    if (newVolume > 0) {
      setPreviousVolume(newVolume);
    }
  }, [setVolumeSmooth]);

  const handleVolumeIconClick = useCallback(() => {
    if (localVolume > 0) {
      setPreviousVolume(localVolume);
      setLocalVolume(0);
      setVolumeSmooth(0);
    } else {
      const newVolume = previousVolume > 0 ? previousVolume : 0.5;
      setLocalVolume(newVolume);
      setVolumeSmooth(newVolume);
    }
  }, [localVolume, previousVolume, setVolumeSmooth]);

  const handlePlayPauseClick = useCallback(() => {
    if (currentSong) {
      togglePlayPause();
    }
  }, [currentSong, togglePlayPause]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleSeek = useCallback((value) => {
    setCurrentTimeAndSeek(value[0]);
  }, [setCurrentTimeAndSeek]);

  if (!currentSong || !showGlobalControls) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 shadow-lg p-4 transition-colors duration-200 z-[9999]">
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row sm:items-center">
        {/* Duration bar for mobile */}
        <div className="w-full mb-2 sm:hidden"
             onMouseEnter={() => setIsPlaybackHovered(true)}
             onMouseLeave={() => setIsPlaybackHovered(false)}>
          <Slider
            value={[currentTime || 0]}
            max={duration || 0}
            step={1}
            onValueChange={handleSeek}
            className={`w-full ${isPlaybackHovered ? 'range-thumb-visible' : 'range-thumb-hidden'}`}
          />
          <div className="flex justify-between text-xs text-gray-500 dark:text-gray-400 mt-1">
            <span>{formatTime(currentTime || 0)}</span>
            <span>{formatTime(duration || 0)}</span>
          </div>
        </div>

        {/* Play/Pause button - centered on mobile */}
        <div className="flex justify-center sm:justify-start sm:w-auto">
          <button 
            onClick={handlePlayPauseClick} 
            className="p-2 rounded-full bg-indigo-600 text-white hover:bg-indigo-700 transition-all duration-300 ease-in-out focus:outline-none"
          >
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </button>
        </div>
        
        {/* Duration bar for desktop */}
        <div className="hidden sm:block flex-grow mx-10"
             onMouseEnter={() => setIsPlaybackHovered(true)}
             onMouseLeave={() => setIsPlaybackHovered(false)}>
          <Slider
            value={[currentTime || 0]}
            max={duration || 0}
            step={1}
            onValueChange={handleSeek}
            className={`w-full ${isPlaybackHovered ? 'range-thumb-visible' : 'range-thumb-hidden'}`}
          />
          <div className="flex justify-between text-xs text-gray-500 dark:text-gray-400 mt-1">
            <span>{formatTime(currentTime || 0)}</span>
            <span>{formatTime(duration || 0)}</span>
          </div>
        </div>

        {/* Volume controls (hidden on mobile) */}
        <div className="hidden sm:flex items-center w-32"
             onMouseEnter={() => setIsVolumeHovered(true)}
             onMouseLeave={() => setIsVolumeHovered(false)}>
          <button
            onClick={handleVolumeIconClick}
            className="focus:outline-none mr-2 text-gray-600 dark:text-gray-300"
          >
            <VolumeIcon />
          </button>
          <Slider
            value={[localVolume]}
            max={1}
            step={0.01}
            onValueChange={handleVolumeChange}
            className={`w-full ${isVolumeHovered ? 'range-thumb-visible' : 'range-thumb-hidden'}`}
          />
        </div>
      </div>
    </div>
  );
}

export default GlobalControls;