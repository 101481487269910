import React, { useState, useRef, useEffect } from 'react';
import { FileDrop } from 'react-file-drop';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { addDoc, collection } from 'firebase/firestore';
import { storage, db } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import { generateWaveformData } from './Waveform';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import axios from 'axios';

function Upload() {
  const [file, setFile] = useState(null);
  const [normalizedFile, setNormalizedFile] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [directLink, setDirectLink] = useState('');
  const [duration, setDuration] = useState('0:00');
  const [waveformData, setWaveformData] = useState(null);
  const fileInputRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [ffmpegLoaded, setFfmpegLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingStep, setProcessingStep] = useState('');
  const ffmpegRef = useRef(null);
  const [sendDiscordNotification, setSendDiscordNotification] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [originalFileName, setOriginalFileName] = useState('');
  const [isPreviewPlaying, setIsPreviewPlaying] = useState(false);
  const previewAudioRef = useRef(new Audio());

  useEffect(() => {
    let mounted = true;

    const loadFFmpeg = async () => {
      if (!ffmpegRef.current) {
        ffmpegRef.current = new FFmpeg();
        try {
          await ffmpegRef.current.load();
          if (mounted) {
            setFfmpegLoaded(true);
            console.log('FFmpeg loaded successfully');
          }
        } catch (error) {
          console.error('Failed to load FFmpeg:', error);
          if (mounted) {
            setError('Failed to initialize audio processing. Please try again.');
          }
        }
      }
    };

    loadFFmpeg();

    return () => {
      mounted = false;
      if (ffmpegRef.current) {
        ffmpegRef.current.terminate();
        ffmpegRef.current = null;
        console.log('FFmpeg terminated');
      }
    };
  }, []);

  const resetFFmpeg = async () => {
    if (ffmpegRef.current) {
      await ffmpegRef.current.terminate();
      ffmpegRef.current = new FFmpeg();
      await ffmpegRef.current.load();
      console.log('FFmpeg reset successfully');
    }
  };

  const calculateDuration = (file) => {
    console.log('Calculating duration...');
    const audio = new Audio();
    audio.src = URL.createObjectURL(file);
    
    audio.addEventListener('loadedmetadata', () => {
      const durationInSeconds = Math.round(audio.duration);
      const minutes = Math.floor(durationInSeconds / 60);
      const seconds = durationInSeconds % 60;
      setDuration(`${minutes}:${seconds.toString().padStart(2, '0')}`);
      console.log('Duration calculated:', `${minutes}:${seconds.toString().padStart(2, '0')}`);
    });
  };

  const generateWaveform = async (file) => {
    console.log('Generating waveform...');
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const arrayBuffer = await file.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    const waveformData = await generateWaveformData(audioBuffer);
    setWaveformData(waveformData);
    console.log('Waveform generated');
  };

  const normalizeAndConvertToMp3 = async (file) => {
    console.log('Starting normalization and conversion...');
    if (!ffmpegLoaded) {
      setError('FFmpeg is not ready. Please try again shortly.');
      return null;
    }

    try {
      setProcessingStep('Writing file to FFmpeg');
      await ffmpegRef.current.writeFile('input', await fetchFile(file));
      console.log('File written to FFmpeg');

      setProcessingStep('Applying normalization and converting to MP3');
      await ffmpegRef.current.exec([
        '-i',
        'input',
        '-af',
        'loudnorm',
        '-ar',
        '44100',
        '-b:a',
        '192k',
        'output.mp3'
      ]);
      console.log('Normalization and conversion completed');

      setProcessingStep('Reading processed file');
      const data = await ffmpegRef.current.readFile('output.mp3');
      console.log('Processed file read');

      const normalizedBlob = new Blob([data.buffer], { type: 'audio/mpeg' });

      setProcessingStep('Cleaning up FFmpeg filesystem');
      await ffmpegRef.current.deleteFile('input');
      await ffmpegRef.current.deleteFile('output.mp3');
      console.log('FFmpeg filesystem cleaned');

      return normalizedBlob;
    } catch (err) {
      console.error('FFmpeg error:', err);
      setError('Error processing audio. Please try a different file.');
      return null;
    }
  };

  const handleFiles = async (files) => {
    console.log('Handling files...');
    if (files[0]) {
      const selectedFile = files[0];
      
      // Update title immediately
      setTitle(selectedFile.name.split('.').slice(0, -1).join('.'));

      // Set the last modified date
      const lastModifiedDate = new Date(selectedFile.lastModified).toISOString().split('T')[0];
      setDate(lastModifiedDate);

      // Store the original file name
      setOriginalFileName(selectedFile.name);

      // Create a new audio element for preview
      const audioUrl = URL.createObjectURL(selectedFile);
      previewAudioRef.current.src = audioUrl;
      previewAudioRef.current.volume = 0.75;

      setFile(selectedFile);  // Set the file state for the original file
      setIsPreviewPlaying(false);  // Reset play state

      if (!ffmpegLoaded) {
        setError('Audio processing is not ready. Please wait a moment and try again.');
        return;
      }

      setIsProcessing(true);
      setProcessingStep('Starting file processing');

      // Reset FFmpeg before processing new file
      await resetFFmpeg();

      const processedFile = await normalizeAndConvertToMp3(selectedFile);
      if (!processedFile) {
        setIsProcessing(false);
        return;
      }

      setNormalizedFile(processedFile);
      
      setProcessingStep('Calculating duration');
      calculateDuration(processedFile);
      
      setProcessingStep('Generating waveform');
      await generateWaveform(processedFile);
      
      setIsProcessing(false);
      setProcessingStep('');
      console.log('File processing completed');

      // Update the file state with the processed file, but keep the original name
      setFile(new File([processedFile], originalFileName, { type: 'audio/mpeg' }));
    }
  };

  const handlePreviewPlayPause = () => {
    if (previewAudioRef.current) {
      if (isPreviewPlaying) {
        previewAudioRef.current.pause();
      } else {
        previewAudioRef.current.play();
      }
      setIsPreviewPlaying(!isPreviewPlaying);
    }
  };

  useEffect(() => {
    const audio = previewAudioRef.current;
    audio.addEventListener('ended', () => setIsPreviewPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setIsPreviewPlaying(false));
      URL.revokeObjectURL(audio.src);
    };
  }, []);

  const handleUpload = async () => {
    console.log('Starting upload...');
    if (!normalizedFile) {
      setError('Please select a file to upload.');
      return;
    }

    // Generate a unique filename using timestamp and original file name
    const timestamp = Date.now();
    const fileExtension = originalFileName.split('.').pop();
    const uniqueFileName = `${timestamp}_${title.replace(/\s+/g, '_')}.${fileExtension}`;

    const storageRefInstance = ref(storage, `songs/${uniqueFileName}`);
    const uploadTask = uploadBytesResumable(storageRefInstance, normalizedFile);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(prog);
        console.log(`Upload progress: ${prog}%`);
      },
      (error) => {
        console.error('Upload failed', error);
        setError('Upload failed. Please try again.');
      },
      async () => {
        console.log('Upload completed, getting download URL...');
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        console.log('Adding document to Firestore...');
        const docRef = await addDoc(collection(db, 'songs'), {
          title,
          description,
          url: downloadURL,
          date,
          duration,
          waveformData,
          originalFileName,
          uploadedFileName: uniqueFileName,
        });

        const anchorLink = `https://music.steventhings.com/#song-${docRef.id}`;
        setDirectLink(anchorLink);
        setShowPopup(true);
        setSuccess('Song uploaded successfully!');
        console.log('Upload process completed');
        
        if (sendDiscordNotification) {
          try {
            const embedColor = 3447003;
            const faviconUrl = 'https://music.steventhings.com/favicon.ico';

            await axios.post('https://discord.com/api/webhooks/1292513418292760596/pDK6_7ps-1_QkWbld0tgQPcQ3DhgZT1_NjwVIkhNIqgZzOOq3ntbcRSnwvfPsaNzpWBV', {
              embeds: [{
                title: `🔥${title}🔥`,
                description: `Has been added to the vault.`,
                color: embedColor,
                fields: [
                  {
                    name: "Description",
                    value: description || "No description provided",
                    inline: false
                  },
                  {
                    name: "Duration",
                    value: duration,
                    inline: true
                  },
                  {
                    name: "Upload Date",
                    value: new Date().toLocaleDateString(),
                    inline: true
                  }
                ],
                url: anchorLink,
                thumbnail: {
                  url: "https://music.steventhings.com/monke.jpg"
                }
              }]
            });
            console.log('Discord notification sent');
          } catch (error) {
            console.error('Failed to send Discord notification:', error);
          }
        }

        // Clear form for new upload
        setFile(null);
        setNormalizedFile(null);
        setTitle('');
        setDescription('');
        setDate('');
        setProgress(0);
        setDuration('0:00');
        setWaveformData(null);
        setOriginalFileName('');
        setIsPreviewPlaying(false);
        if (previewAudioRef.current) {
          previewAudioRef.current.pause();
          previewAudioRef.current.src = '';
        }
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    );
  };

  const copyDirectLink = () => {
    navigator.clipboard.writeText(directLink);
    alert('Anchor link copied to clipboard!');
  };

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.75;
    }
  }, [file]);

  return (
    <div className="max-w-xl mx-auto mt-10 p-8 bg-white rounded-lg shadow-xl">
      <Link to="/" className="text-indigo-600 hover:text-indigo-800 mb-4 inline-block">&larr; Back to Home</Link>
      <h2 className="text-3xl font-bold mb-6 text-gray-900">Upload a Song</h2>
      
      <FileDrop
        onDrop={(files) => handleFiles(files)}
        onTargetClick={onTargetClick}
      >
        <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 mb-6 text-center cursor-pointer hover:border-indigo-500 custom-transition">
          <input 
            ref={fileInputRef}
            onChange={(e) => handleFiles(e.target.files)}
            type="file"
            className="hidden"
            accept="audio/*"
          />
          <p className="text-gray-600">Drag 'n' drop an audio file here, or click to select a file</p>
        </div>
      </FileDrop>

      {file && (
        <div className="mb-4 text-gray-600">
          <p>Selected file: {originalFileName}</p>
          <button
            onClick={handlePreviewPlayPause}
            className="mt-2 px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
          >
            {isPreviewPlaying ? 'Pause' : 'Play'} Preview
          </button>
          <audio ref={audioRef} />
        </div>
      )}

      {isProcessing && (
        <div className="mb-4 text-blue-600">
          <p>Processing: {processingStep}</p>
        </div>
      )}

      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Title"
        className="input mb-4"
      />

      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Description (optional)"
        className="input mb-4"
      />

      <input
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        className="input mb-6"
      />

      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          id="discordNotification"
          checked={sendDiscordNotification}
          onChange={(e) => setSendDiscordNotification(e.target.checked)}
          className="mr-2"
        />
        <label htmlFor="discordNotification" className="text-gray-700">
          Send Discord notification on upload
        </label>
      </div>

      <button
        onClick={handleUpload}
        className={`button button-primary w-full ${(isProcessing || !normalizedFile || !ffmpegLoaded) ? 'opacity-50 cursor-not-allowed' : ''}`}
        disabled={isProcessing || !normalizedFile || !ffmpegLoaded}
      >
        {isProcessing ? 'Processing...' : ffmpegLoaded ? 'Upload' : 'Preparing...'}
      </button>

      {progress > 0 && (
        <div className="mt-4 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
          <div className="bg-indigo-600 h-2.5 rounded-full" style={{width: `${progress}%`}}></div>
        </div>
      )}

      {error && <p className="text-red-500 mt-4">{error}</p>}
      {success && <p className="text-green-500 mt-4">{success}</p>}

      {directLink && (
        <div className="mt-6">
          <p className="text-gray-700 mb-2">Direct link:</p>
          <input type="text" value={directLink} readOnly className="input mb-2" />
          <button onClick={copyDirectLink} className="button button-secondary w-full">Copy Direct Link</button>
        </div>
      )}

      {showPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Song Uploaded Successfully</h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-gray-500">Here's your anchor link:</p>
                <input type="text" value={directLink} readOnly className="mt-2 p-2 w-full border rounded" />
              </div>
              <div className="items-center px-4 py-3">
                <button
                  onClick={copyDirectLink}
                  className="px-4 py-2 bg-indigo-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-300"
                >
                  Copy Link
                </button>
                <button
                  onClick={() => setShowPopup(false)}
                  className="mt-3 px-4 py-2 bg-gray-100 text-gray-700 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Upload;
